import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import axios from 'axios'
import moment from 'moment'

function gup(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(url);
    return results == null ? null : results[1];
}

export function perc2color(perc) {
    let r, g, b = 0;
    if (perc < 50) {
        r = 255;
        g = Math.round(5.1 * perc);
    } else {
        g = 255;
        r = Math.round(510 - 5.10 * perc);
    }
    let h = r * 0x10000 + g * 0x100 + b * 0x1;
    return '#' + ('000000' + h.toString(16)).slice(-6);
}

export default function UptimePanel(props) {
    const id = gup('id');

    const [points, setPoints] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedTimestamp, setSelectedTimestamp] = useState(+moment().startOf('day'));

    useEffect(() => {
        if (loading == true) {
            return;
        }
        if (id == undefined){
            window.location.href = 'https://iproxy.online';
            return null;
        }
        let from = +moment(selectedTimestamp).startOf('day');
        let to = +moment(selectedTimestamp).endOf('day');
        setLoading(true);
        setPoints([]);
        axios.get(`https://iproxy.online/api-rt/v2/pinger/phone/${id}/${from}/${to}/group/${60 * 60 * 1000}`).then(d => d.data).then(arr => {
            setPoints(arr);
            setLoading(false);
        });
    }, [id, selectedTimestamp]);

    let xMap = points.reduce((mp, x) => ({...mp, [`t-${x.t}`]: x}), {});
    const xFrom = +moment(selectedTimestamp).startOf('day');
    let items = [];
    for (let i = 0; i < 24; i++) {
        let t = +xFrom + i * 3600000;
        let val = xMap[`t-${t}`];
        let perc = val?.percent == undefined ? (+t > +new Date() ? undefined : 0) : val?.percent;
        if (perc != undefined) {
            perc = perc.toFixed(1);
        }
        items.push({
            t: t,
            value: perc,
            color: perc == undefined ? undefined : perc2color(+perc)
        });
    }

    return (
        <Wrapper>

            <TopPlaceholder>
                <Side onClick={() => {
                    setSelectedTimestamp(+moment(selectedTimestamp).add(-1, 'days'));
                }}>
                    {' < '}
                </Side>
                <CenterItem>
                    {`${moment(selectedTimestamp).format('DD.MM.YYYY')}`}
                </CenterItem>
                <Side onClick={() => {
                    setSelectedTimestamp(+moment(selectedTimestamp).add(1, 'days'));
                }}>
                    {' > '}
                </Side>
            </TopPlaceholder>

            <ContentPlaceholder>
                <Table>
                    <Thead>
                        <Tr>
                            {items.map((x, i) => {
                                return (
                                    <Th key={x.t}>
                                        {moment(x.t).format('H')}
                                    </Th>
                                )
                            })}
                        </Tr>
                    </Thead>
                    <tbody>
                    <Tr>
                        {items.map((x, i) => {
                            return (
                                <Td key={x.t} color={loading == true ? undefined : x.color} >
                                    {x.value == undefined ? null : `${x.value}%`}
                                </Td>
                            )
                        })}
                    </Tr>
                    </tbody>
                </Table>
            </ContentPlaceholder>

            {loading == false ? null :
                <BottomLoadingPlaceholder>
                    {'loading...'}
                </BottomLoadingPlaceholder>
            }

        </Wrapper>
    );
}

const BottomLoadingPlaceholder = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 10px;
`;

const Side = styled.div`
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const CenterItem = styled.div`
  margin-left: 15px;
  margin-right: 15px;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ContentPlaceholder = styled.div`
  margin-top: 10px;
`;


const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Tr = styled.tr`

`;

const Th = styled.th`
  border: 1px solid lightgrey;
  font-size: 10px;
`;

const Td = styled.td`
  box-sizing: border-box;
  border: 1px solid lightgrey;
  padding: 3px;
  font-size: 10px;
  text-align: center;
  background: ${props => (props.color == undefined ? 'transparent' : props.color)};
  height: 50px;
`;


const Thead = styled.thead`

`;
