import './App.css';
import KycPanel from "./KycPanel";
import UptimePanel from "./UptimePanel";

function App() {
    return (
        <div className="App">
            <UptimePanel/>
        </div>
    );
}

export default App;

